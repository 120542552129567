<template>
 <div class="property-main-layout-container">
    <div class="property-projects-container">
      <div class="header-container">
        <el-input
            class="search-input w-50 m-2"
            placeholder="Search"
            v-model="keyword"
        >
            <template #prefix>
                <el-icon class="el-input__icon"><Search /></el-icon>
            </template>
        </el-input>
        <div class="default-action-container">
            <p class="header">Projects <span>{{projects.length}}</span></p>
            <div class="action-container">
                <div class="sorting-container">
                    <!-- <p class="sort">Sort by</p>
                    <el-divider direction="vertical" /> -->
                    <p class="list-view" @click="setToListView">List view</p>
                    <p class="list-view" @click="setToTileView">Tile view</p>
                    <!-- <p class="map-view" @click="setToMapView">Map view</p> -->
                </div>
                <div class="add-new-cta-container" v-if="canCreate" @click="routeToStartNewProject">
                    <img src="@/assets/icons/add-button-icon.svg">
                    <p>New</p>
                </div>
            </div>
        </div>
      </div>
      <ProjectViewComponent v-if="!isLoading" :isWithArrowPreview="true" :type="viewType" @view-preview="viewPreview" :data="projects"/>
      <NoProjectsComponent v-if="(projects.length <= 0 && !isLoading) || (projects.length === null && !isLoading)"/>
      <Loader v-show="!projects.length && isLoading" style="top: 200% !important;"/>
    </div>
    <!-- <el-scrollbar class="preview-container">
        <PreviewComponent v-if="isFileClicked"  @close-preview="closePreview"/>
        <NoFilePreviewComponent v-else/>
    </el-scrollbar> -->
 </div>
</template>
<script>
import { Search } from '@element-plus/icons';
import { ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import Loader from '@/core/components/ui/Loader.vue';
import { PAGE_LIMIT } from '@/core/constants';
import NoProjectsComponent from '@/modules/projects/components/NoProjectsComponent.vue';
// import NoFilePreviewComponent from '@/modules/property-details-children/components/NoFilePreviewComponent.vue';
// import PreviewComponent from '@/modules/property-details-children/components/PreviewComponent.vue';
import ProjectViewComponent from '@/modules/property-details-children/components/ProjectViewComponent.vue';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USERS_STORE } from '@/store/modules/users';

import { PROJECT_POST_ROUTE } from '../../project-post/routes';

export default {
  components: {
    Search,
    // NoFilePreviewComponent,
    // PreviewComponent,
    ProjectViewComponent,
    NoProjectsComponent,
    Loader
  },

  data() {
    return {
      totalProjects: 0,
      projects: [],
      keyword: '',
      currentFilters: {
        page: 1,
        limit: PAGE_LIMIT,
        projectJobStatusIds: [],
        search: '',
        propertyIds: []
      }
    };
  },

  watch: {
    keyword: {
      immediate: true,
      deep: true,
      handler(keyword) {
        this.onSearch(encodeURIComponent(keyword));
      }
    }
  },
  computed: {
    ...mapGetters([
      'isLoading',
    ]),
    ...mapGetters(USERS_STORE, ['wsRole', 'isWsAdmin']),

    canCreate() {
      const { wsRole, isWsAdmin } = this;
      let show = true;

      if (wsRole && !isWsAdmin) {
        show = !show;
      }

      return show;
    },
  },
  created() {
    const { currentFilters } = this;
    const { id } = this.$route.params;

    currentFilters.propertyIds.push(id);

    this.loadProjects(currentFilters);
  },
  beforeUnmount() {
    const { currentFilters } = this;

    currentFilters.propertyIds = [];
    this.loadProjects(currentFilters);
  },
  methods: {
    ...mapActions(['setIsLoading']),

    ...mapActions(PROJECTS_STORE, ['getProjectPageList']),

    onSearch(keyword) {
      const { currentFilters } = this;
      const { id } = this.$route.params;

      currentFilters.search = keyword;
      currentFilters.propertyIds.push(id);

      this.loadProjects(currentFilters);
    },

    async loadProjects(filters) {
      await this.getProjectPageList(filters).then((response) => {
        const { data, totalCount } = response;
        this.totalProjects = totalCount;
        this.projects = data;
      })
        .finally(() => {
          this.setIsLoading(false);
        });
    },

    routeToStartNewProject() {
      const { id } = this.$route.params;
      this.$router.push({ name: PROJECT_POST_ROUTE, params: { propertyId: id } });
    },

  },

  setup() {
    const input = ref('');
    const isFileClicked = ref(false);
    const viewType = ref('list');
    // SAMPLE PROJECT DATA //
    const projectData = ref([
    ]);

    function setToListView() {
      viewType.value = 'list';
    }

    function setToTileView() {
      viewType.value = 'tile';
    }

    function setToMapView() {
      viewType.value = 'map';
    }

    function viewPreview() {
      isFileClicked.value = true;
    }
    function closePreview() {
      isFileClicked.value = false;
    }

    return {
      closePreview,
      input,
      isFileClicked,
      viewType,
      setToListView,
      setToMapView,
      setToTileView,
      viewPreview,
      projectData,
    };
  },
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.property-main-layout-container {
    width:68%;
    // position: relative;
    // height: calc(100% - 8px);

    .property-projects-container * p {
    margin: 0;
    }
    .property-projects-container {
        display:flex;
        flex-direction: column;
        height:100%;
        width: 100%;
        gap: 2rem;
        max-width:1030px;
        position: relative;

        .header-container {
          display:flex;
          flex-direction: column;
          height:100%;
          width: 100%;
          gap: 2rem;

            .search-input {
                max-width: 418px;
            }
            :deep(.el-input__inner) {
                background: rgba(12, 15, 74, 0.05);
                border: none;
                color: #0C0F4A;
                border-radius: 30px;
                font-size: 14px;
                padding: 20px 20px 20px 45px;
                font-family: Arial;
            }

            :deep(.el-input__prefix) {
                left:15px;
                color:#0C0F4A;
                top:-2px;
            }

            .default-action-container {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .header {
                font-size: 28px;
                font-weight: 700;
                line-height: 25px;
                letter-spacing: 0.25px;
                text-align: left;
                color: #0C0F4A;
                display:flex;
                gap: 1rem;
                align-items: center;

                span {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 25px;
                    letter-spacing: 0.25px;
                    text-align: center;
                    color: #0C0F4A;
                    border: 1px solid #FAA100;
                    box-sizing: border-box;
                    border-radius: 16px;
                    width: 42px;
                    height: 42px;
                    display:flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .action-container {
                display:flex;
                gap: 3rem;
                justify-content:center;
                align-items:center;

                  .sorting-container {
                      display:flex;
                      align-items:center;
                      gap: 2rem;

                      p {
                          margin: 0;
                          display:flex;
                          justify-content:center;
                          align-items:center;
                          cursor: pointer;
                      }
                      .sort {
                          font-size: 14px;
                          font-weight: 600;
                          line-height: 25px;
                          letter-spacing: 0.25px;
                          text-align: left;
                          color: #0C0F4A;
                      }
                      .list-view, .map-view {
                          font-size: 12px;
                          font-weight: 600;
                          line-height: 16px;
                          letter-spacing: 0.4000000059604645px;
                          text-align: left;
                          color: rgba(12, 15, 74, 0.4);
                      }

                      .el-divider {
                      margin: 0;
                      //    width: 2px;
                      }
                  }

                  .add-new-cta-container {
                      display:flex;
                      justify-content: center;
                      align-items: center;
                      gap: .5rem;
                      cursor: pointer;
                      p {
                      cursor: pointer;
                      margin:0;
                      font-size: 18px;
                      font-weight: 700;
                      line-height: 22px;
                      letter-spacing: 0.25px;
                      text-align: left;
                      color: #0C0F4A;
                      }
                  }
              }
            }
        }
    }
    .preview-container {
        width: 28%;
        max-width : 420px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 20px;
    }
}

@include media-md-max-width() {
  .property-main-layout-container {
    width: 100%;
    // height: calc(100% - 3px);
    .preview-container {
      display: none;
    }
  }
}
@include media-sm-max-width() {
  .property-main-layout-container {
    // margin-top: 4rem;
    .property-projects-container {
      gap: 1rem;
      .header-container {
        gap: 1rem;
        .default-action-container {
          .action-container {
            .sorting-container {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
